.golfBoard {
  margin: 0 auto;
  max-width: 1228px;
  width: 100%;
  padding: 0 24px;

  & .golfHead {
    text-align: center;

    & h2 {
      font-size: 32px;
      font-weight: 700;
      line-height: 48px;
      color: #000;
      margin-bottom: 7px;
    }

    & p {
      color: #000;
      font-size: 16px;
      font-weight: 400;
      line-height: 18.75px;
      margin-bottom: 40px;
    }
  }

  & .golfGrid {
    gap: 15px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    @media(max-width: 1120px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media(max-width: 600px) {
      grid-template-columns: repeat(1, 1fr);
    }

    & .golfCard {
      height: 460px;
      padding: 15px;
      display: flex;
      justify-content: end;
      flex-direction: column;
      align-items: flex-start;
      border-radius: 10px;

      & .golfCartHeading {
        font-size: 14px;
        font-weight: 700;
        line-height: 19.07px;
        color: #fff;
        margin-top: 0px;
        margin-bottom: 20px;
      }

      & .bottomButton {
        width: 100%;

        & button {
          width: 100%;
          height: 52px;
          padding: 15px;
          display: block;
          color: #0E0E0E;
          cursor: pointer;
          font-size: 18px;
          font-weight: 700;
          border-radius: 4px;
          line-height: 21.09px;
          border: 1px solid #20F329;
          background-color: #20F329;

          &:first-child {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
