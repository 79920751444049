.root {
  /* SectionContainer defaults */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  /* Hero specific */
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.addKartButton {
  background: #20f329;
  font-family: Roboto;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.09px;
  text-align: left;
  color: #0e0e0e;
  border: none;
  border-radius: 4px;
  height: 52px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  min-height: 52px;

  &:hover {
    opacity: 0.8;
  }
}

:global(#waitlist-hero) {
  width: 100%;
  height: 100vh;
  clear: both;
  position: relative;
  display: flex;
  padding-top: 72px;

  @media (max-width: 1200px) {
    height: 100%;
  }

  @media (max-width: 1024px) {
    padding-top: 0;
  }

  & > div {
    &:first-child {
      width: 100%;
      left: unset;

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
}

.heroWrapper {
  display: flex;
  align-items: flex-end;
  gap: 50px;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 1440px) {
    padding: 0 34px;
    flex-wrap: wrap;
  }

  & .videoSection {
    display: flex;
    gap: 20px;
    align-items: center;

    & iframe {
      border-radius: 10px;

      @media (max-width: 1024px) {
        width: 100%;
      }
    }
  }
}
