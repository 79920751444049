.root {
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }
}


:global(#featured_locations-slider) {
  & > div {
   &>div{
    & p {
      &:nth-child(2) {
        display: none;
      }
    }
   }
  }
}
.postGolfButton {
  margin-top: 26px;
  margin-top: 0;
  background: #20f329;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.09px;
  text-align: center;
  
  
  color: #0e0e0e;
  border: none;
  border-radius: 4px;
  height: 52px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  width: calc(100% - 26px);
  padding: 10px;
  margin: 20px 13px 0px 13px;
  min-height: 40px;
  @media (max-width: 1023px) {
    height: 42px;
    font-size: 16px;
    line-height: 20px;
  }
  &:hover,
  &:focus {
    background-color: #20f329;
    box-shadow: unset;
    text-decoration: unset;
  }
}
