@import '../../../../styles/customMediaQueries.css';

.questionForm {
  border-radius: 10px;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.3);
  box-shadow: 0px 20px 40px 0px #0000000F;
  height: 100%;



  @media(max-width: 768px) {
    width: 100%;
    /* background: linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.8) 100%); */
    /* backdrop-filter: blur(10px); */
    padding: 30px;
  }

  & input,
  select,
  textarea {
    &::placeholder {
      font-size: 10px !important;

      @media(min-width: 2000px) {
        font-size: 16px !important;
      }
    }
  }

  & .formHeading {
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    margin-bottom: 10px;


    @media(min-width: 2000px) {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 20px;
    }
  }

  & .inputBox {
    margin-bottom: 10px;

    @media(max-width: 768px) {
      margin-bottom: 10px;
    }

    & label {
      color: #fff;
      font-size: 13px;
      padding-bottom: 8px;

      @media(min-width: 2000px) {
        font-size: 16px;
        padding-bottom: 8px;
      }
    }

    & input {
      height: 35px;

      @media(min-width: 2000px) {
        height: 45px;
      }
    }

    & select {
      height: 35px;
      padding: 0 12px;

      @media(min-width: 2000px) {
        height: 45px;
      }

      @media(min-width: 768px) {
        padding: 0 16px;
      }
    }

    & input,
    & select,
    & textarea {
      border-radius: 4px;
      border: 1px solid #fff;
      background-color: #ffffff00;
      color: #ffffff;
      box-shadow: none;
      font-size: 13px;

      &::placeholder {
        color: #ffffff;
      }

      @media(min-width: 2000px) {
        font-size: 16px;
      }
    }

    & textarea {
      @media(max-width: 1440px) {
        min-height: 50px;
        height: 50px !important;
      }
    }

    & option {
      color: #0E0E0E;
    }
  }

  & .submitButton {
    color: #0E0E0E;
    background-color: #20F329;
    margin-top: 24px;
    min-height: 40px;

    &:disabled {
      color: gray;
      background-color: var(--colorGrey100);
    }
  }
}

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  @media(min-width: 2000px) {
    margin-bottom: 20px;
  }

  @media(max-width: 600px) {
    gap: 10px;
    flex-wrap: wrap;
  }

  & .inputBox {
    width: calc(50% - 9px);
    margin-bottom: 0;

    @media(max-width: 600px) {
      width: 100%;
    }
  }
}

.fullInput {
  margin-bottom: 10px;

  @media(min-width: 2000px) {
    margin-bottom: 15px;
  }

  & .inputBox {
    width: 100%;
    margin-bottom: 0;
  }
}