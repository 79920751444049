.link {
  display: inline;
  color: var(--marketplaceColor);

  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.customLink {
  cursor: pointer;

  &:hover {
    color: var(--marketplaceColor);
  }
}
