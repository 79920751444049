:global(#hero_section) {
  min-height: 100vh;
  @media (max-width: 1023px) {
    min-height: 863px;
  }

  & img {
    object-position: bottom;
    @media (max-width: 450px) {
      display: none;
    }
  }

  &>div {
    &:nth-child(1) {
      &>div {
        background-color: unset !important;

        @media (max-width: 450px) {
          opacity: 1 !important;
          background-image: url('../../assets/Hero\ section\ \(5\).png');
          background-size: cover;
        }
      }
    }
  }

  &>div {
    &:nth-child(2) {
      position: absolute;
      bottom: 60px;
      left: 32px;
      right: 32px;
      @media (max-width: 767px) {
        bottom: 47px;
      }
    }
  }

  & header {
    background: linear-gradient(180deg,
        rgba(255, 255, 255, 0.276) 0%,
        rgba(255, 255, 255, 0.188) 100%),
      linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
    backdrop-filter: blur(5px);
    border-radius: 20px;
    padding: 30px 25px;
    width: fit-content;

    & h1 {
      font-family: Roboto;
      font-size: 26px;
      font-weight: 700;
      line-height: 30.47px;
      max-width: 100%;
      padding: 0;
    }

    & p {
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
      margin: 10px 0 50px;
      padding: 0;
    }

    & a {
      margin-top: 20px;
      background: #20f329;
      font-family: Roboto;
      font-size: 18px;
      font-weight: 700;
      line-height: 21.09px;
      text-align: left;
      color: #0e0e0e;
      border: none;
      border-radius: 4px;
      height: 52px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

:global(#renting_golf_cart) {
  display: none;
}

:global(#featured_locations) {
  background-color: #F9F9F9;
  & img {
    object-fit: contain;
    height: 230px;
    width: 100%;
  }

  & header {
    @media (max-width: 767px) {
      padding: 0 20px;
    }

    & h2 {
      font-family: Roboto;
      font-size: 32px;
      font-weight: 700;
      line-height: 37.5px;
      color: #000;
      padding: 0;
    }

    & p {
      font-family: Roboto;
      font-size: 16px;
      font-weight: 400;
      line-height: 18.75px;
      color: #000;
      padding: 0;
      margin: 7px 0 0px;
      text-wrap: pretty;
    }
  }

  &>div {
    margin: 100px 0;
    padding: 60px 0 60px;
    background-color: #fff;
  }

  & :global(#featured_locations-container) {
    padding-top: 0;
 
  }

  & :global(#featured_locations-slider) {
    padding-top: 40px;
    background-color: #fff;

    &>div {
      position: relative;
      padding-right: 0 !important;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 48.28%, #000000 107%);
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
      border-radius: 8px;
      transform: translateX(100px);
      margin-right: 13px;
      max-width: 300px;
      @media (max-width: 767px) {
        max-width: 260px;
        transform: translateX(20px);
      }

      &>div {
        &:nth-child(1) {
          height: 100%;

          &>div>div {
            padding-bottom: 148% !important;

            &>div {
              width: 50%;
              margin: auto;
            }
          }
        }

        &:nth-child(2) {
          position: absolute;
          bottom: 0;
          border-radius: 8px;
          /* box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3); */

          padding-top: 16px;

          & a {
            margin-top: 0;
            background: #20f329;
            font-family: Roboto;
            font-size: 18px;
            font-weight: 700;
            line-height: 21.09px;
            text-align: center;
            

            color: #0e0e0e;
            border: none;
            border-radius: 4px;
            height: 52px;
            width: calc(100% - 26px);
            padding: 10px;
            margin: 10px 13px 16px 13px;
            display: flex;
            align-items: center;
            justify-content: center;
            @media (max-width: 1023px) {
              height: 42px;
              font-size: 16px;
              line-height: 20px;
            }
            &:hover {
              text-decoration: unset;
            }
          }

          & h3 {
            padding: 0 20px;
            font-family: Open Sans;
            font-size: 14px;
            font-weight: 700;
            line-height: 19.07px;
            color: #fff;
            padding: 0 20px;
          }

          & p {
            padding: 0 20px;
            font-family: Roboto;
            font-size: 12px;
            font-weight: 300;
            line-height: 14.06px;
            text-align: left;
            color: #fff;
            margin: 14px 0 26px;
          }
        }
      }
    }
  }
}