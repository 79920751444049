@import '../../../../styles/customMediaQueries.css';

.root {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  &:nth-of-type(odd) {
    background-color: var(--colorWhite);
  }

  &:target {
    scroll-margin-top: var(--topbarHeight);

    @media (--viewportMedium) {
      scroll-margin-top: var(--topbarHeightDesktop);
    }
  }
}

.sectionContent {
  padding: 32px 0;
  position: relative;
  word-break: break-word;

  @media (--viewportMedium) {
    padding: 64px 0;
  }
}

.backgroundImageWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.sectionHero {
  padding: 32px 0;
  position: relative;
  word-break: break-word;

  @media (--viewportMedium) {
    padding: 64px 0;
  }

  @media(max-width: 768px) {
    width: 100%;
    padding: 32px 24px;
  }
}

.videoGrid {
  padding: 32px 0;
  position: relative;
  word-break: break-word;

  @media (--viewportMedium) {
    padding: 64px 0;
  }

  &>div {
    max-width: 1024px;
    grid-template-columns: repeat(2, 1fr);

    @media(max-width: 1120px) {
      max-width: 100%;
    }

    @media(max-width: 800px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

.waitlistHero {
  padding: 32px 24px;
  position: relative;
  word-break: break-word;
  display: flex;
  flex-direction: row-reverse;

  max-width: 1360px;
  width: 100%;
  /* padding: 64px 0; */
  gap: 90px;
  margin: auto;
  align-items: center;
  justify-content: center;

  @media(max-width: 1440px) {
    gap: 45px;
  }

  @media(max-width: 1200px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    align-items: center;
    padding: 24px;
  }

  &>div {
    &:first-child {
      display: grid;
      gap: 32px;
      grid-template-columns: repeat(2, 1fr);
      width: 100%;
      margin-top: auto;
      margin-left: 0;
      margin-right: 0;
      padding: 0;

      @media(max-width: 860px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    &:last-child {
      /* width: 407px;
      max-width: unset;
      width: 100%;
      margin: unset; */
    }
  }
}

:global(#footer) {
  &>div {
    padding: 10px 0;
  }
}