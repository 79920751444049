.articleMain {
  /* 720px + (2 * 32px) == 784 */
  max-width: 784px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 0 32px;
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}


.root {
  /* SectionContainer defaults */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  /* Hero specific */
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.addKartButton {
  background: #20f329;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.09px;
  text-align: left;
  color: #0e0e0e;
  border: none;
  border-radius: 4px;
  height: 52px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  min-height: 52px;

  &:hover {
    opacity: 0.8;
  }
}

:global(#waitlist-hero) {
  &>div {
    &:first-child {
      width: 100%;
      left: unset;

      @media(max-width: 768px) {
        width: 100%;
      }
    }
  }
}

.heroWrapper {
  /* display: flex;
  align-items: flex-end;
  gap: 50px;
  width: 100%;
  justify-content: space-between; */
  width: 100%;
  max-width: 530px;

  @media(min-width: 2000px) {
    max-width: 580px;
  }

  @media(max-width: 1440px) {
    /* padding: 0 34px; */
    flex-wrap: wrap;
  }

  @media(max-width: 1400px) {
    width: 40%;
  }

  @media(max-width: 1300px) {
    width: 50%;
  }

  @media(max-width: 1024px) {
    padding-top: 70px;
    width: 60%;
  }

  @media(max-width: 960px) {
    width: 70%;
  }

  @media(max-width: 768px) {
    width: 80%;
    padding-top: 0;
  }

  @media(max-width: 768px) {
    width: 100%;
  }

  & .videoSection {
    display: flex;
    gap: 20px;
    align-items: center;

    & iframe {
      border-radius: 10px;

      @media(max-width:1024px) {
        width: 100%;
      }
    }
  }
}